<template>
  <div>
    <h4 class="header">
      Installing {{ oapp.name }}
    </h4>
    <q-progress :percentage="value"
                color="positive"
    />
    <div class="display">
      <div>
        <p>{{ state }}</p>
      </div>
      <div>
        <p>{{ value }}%</p>
      </div>
    </div>
    <div class="button-container">
      <div v-if="done"
           class="store-button"
           @click="finishInstall()"
      >
        <q-icon name="done" /> FINISH
      </div>
    </div>
  </div>
</template>

<script>

function random(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

export default {
  name: 'Install',
  props: {
    oapp: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      intervalpr: null,
      modalProgress: false,
      submodal: true,
      done: false,
      inProgress: false,
      value: 0,
      msg: ['...', 'Downloading OAPP', 'Extracting OAPP', 'Installing OAPP', 'Installation completed'],
      state: null,
    };
  },
  watch: {
    modalProgress() {
      if (this.modalProgress === true && this.value === 0) {
        this.Og();
      } else {
        this.value = 0;
        clearInterval(this.intervalpr);
      }
    },
  },
  mounted() {
    this.Og();
  },
  methods: {
    finishInstall() {
      this.$store.dispatch('oapp/install', this.oapp.id);
      this.$emit('finishInstall');
    },
    Og: function() {
      this.intervalpr = setInterval(() => {
        setTimeout(() => {
          if (this.value < 100) {
            this.value += +5;
            this.state = this.msg[Math.floor(this.value / 20 - 1)];
            this.inProgress = true;
            this.done = false;
          } else {
            clearInterval(this.intervalpr);
            this.inProgress = false;
            this.done = true;

          }
        }, random(1, 1000));

        if (this.value > 100) {
          this.value = 100;
        }
      }, random(10, 90));
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'


.q-progress
  position relative
  display block
  overflow hidden
  width 100%
  height 25px !important
  background-clip padding-box
  color $primary

.header
  margin-bottom 15px
  margin-top 10px
.display
  display flex
  justify-content space-between

.button-container
  display flex
  justify-content flex-end

.store-button
  font-weight 700
  text-transform uppercase
  cursor pointer
  background-color $positive
  color white
  text-align center
  padding 10px 15px
  border-radius 30px
  margin 20px
  font-size $fs-h1
  transition all 200ms ease
  &--no-margin
      margin 0
  &:hover
      background-color $positive-darker
      color white
      transition all 200ms ease
</style>
