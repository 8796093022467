<template>
  <div>
    <h4 class="header">
      Permissions asked by {{ oapp.name }}:
    </h4>
    <q-list class="permission"
            dense
            noBorder>
      <div v-if="oapp.profiles">
        <q-list-header class="qlist-header">
          Measurement
        </q-list-header>
        <q-item-main v-for="profile in Object.keys(oapp.profiles)"
                     :key="profile"
                     :label="'• ' + oapp.profiles[profile].measure" />
      </div>
      <q-list-header class="qlist-header">
        Spaces
      </q-list-header>
      <q-item-main class="qlist-item"
                   label>
        • All spaces
      </q-item-main>
    </q-list>
    <div class="display">
      <div class="store-button store-button--negative"
           @click="emitAbortInstall">
        <q-icon name="cancel" /> DECLINE
      </div>
      <div class="store-button store-button--positive"
           @click="nextStep">
        <q-icon name="done" /> ACCEPT
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Permission',
  props: {
    oapp: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    emitAbortInstall(abort) {
      this.$emit('abortInstall');
    },
    nextStep() {
      this.$emit('nextStep');
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.q-list-header
  font-size 20px !important

.q-item-main
  margin-left 30px !important
  font-size 20px !important

.display
  display flex
  justify-content flex-end

.qlist
  font-size $fs-h2

.header
  margin-top 10px
  margin-bottom 15px

.store-button
  margin 20px
  padding 10px 15px
  border-radius 30px
  background-color $positive
  color $dark
  text-align center
  text-transform uppercase
  font-weight 700
  font-size $fs-h1
  cursor pointer
  transition all 200ms ease
  &--no-margin
    margin 0

.store-button--negative
  background-color $negative
  color $light
  &:hover
    background-color $negative-darker
    transition all 200ms ease

.store-button--positive
  background-color $positive
  color $dark
  &:hover
    background-color $positive-darker
    transition all 200ms ease
</style>
