<template>
  <q-modal v-if="oapp != undefined"
           v-show="oapp != undefined"
           contentCss="width: 30vw; height: auto;overflow:hidden">
    <div class="full">
      <Permission v-if="step === 1"
      :oapp="oapp"
@abortInstall="emitFinishInstall"
@nextStep="step++"/>
      <Installation v-if="step >= 2"
:oapp="oapp"
@finishInstall="emitFinishInstall"/>
    </div>
  </q-modal>
</template>

<script>
import Permission from '@/app/pages/store/components/permission.vue';
import Installation from '@/app/pages/store/components/installation.vue';

export default {
  name: 'ModalInstall',
  components: { Permission, Installation },
  props: {
    oapp: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    step: 1,

  }),
  methods: {
    emitFinishInstall() {
      this.$emit('finishInstall');
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.full
  padding 30px
  width 100%
  height 100%
</style>
