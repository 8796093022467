<template>
  <div class="store">
    <span class="store-title">
      <OasisLogo class="oasis-logo"
                 noTitle /> Store
    </span>
    <div class="store-list">
      <oapp-card v-for="o in availableOapps"
                 :key="o.id"
                 :oapp="o"
                 :installed="o.installed"
                 class="oapp-card"
                 @install="oappToInstall = o" />
    </div>
    <ModalInstall :oapp="oappToInstall"
@finishInstall="oappToInstall = undefined"/>
    <a href="mailto:oasis@qarnot.com">
      <q-btn size="18px"
             color="secondary"
             label="Download SDK" />
    </a>
  </div>
</template>

<script>
import oappCard from '@/app/pages/store/components/oapp-card.vue';
import ModalInstall from '@/app/pages/store/components/modal-install.vue';
import OasisLogo from '@/app/components/ui/logos/oasis-logo.vue';

export default {
  name: 'OAppStore',
  components: {
    oappCard,
    OasisLogo,
    ModalInstall,
  },
  data() {
    return {
      oappToInstall: undefined
    };
  },
  computed: {
    availableOapps () {
      return this.$store.getters['oapp/available'];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.store
  position relative
  display flex
  flex-direction column
  justify-content center
  align-items center
  margin auto
  min-height 100%
  width 100%
  .q-btn
    position fixed
    right 20px
    bottom 10px
  .store-title
    display flex
    justify-content center
    align-items center
    margin-top $space-4
    margin-bottom $space-4
    padding $space-2
    width 170px
    border-radius 15px
    background-color black
    box-shadow $shadow-elevation-1
    color white
    font-weight 700
    font-size 2.3rem
    user-select none
    .oasis-logo
      padding 0
  .store-list
    display flex
    flex-wrap wrap
    justify-content center
    align-items center
    margin-bottom 50px
    .oapp-card
      margin 10px
</style>
