<template>
  <div class="card-container">
    <div class="card-header">
      <component :is="oapp.icon"
                 class="oapp-icon"
      />
      <div
        class="oapp-name"
      >
        {{ oapp.displayedName || oapp.name }}
      </div>
    </div>
    <div class="card-main">
      <div v-if="!installed"
           class="oapp-description"
      >
        {{ oapp.description }}
      </div>
      <div v-if="!installed"
           class="store-button"
           @click="startInstall"
      >
        Install
      </div>
      <div v-if="installed"
           class="store-button store-button--disabled"
      >
        Config
      </div>
      <div v-if="installed"
           class="store-container"
      >
        <div class="store-button store-button--no-margin"
             @click="uninstall(oapp.id)"
        >
          Uninstall
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OappCard',
  props: {
    oapp: {
      type: Object,
      default: null,
    },
    installed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    startInstall() {
      this.$emit('install');
    },
    uninstall(oappId) {
      this.$store.dispatch('oapp/uninstall', oappId);
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.card-container
    width 220px
    display flex
    flex-direction column
    .card-header
        background-color white
        display flex
        width 100%
        height 230px
        justify-content center
        flex-direction column
        align-items center
        .oapp-icon
            width 125px
            height @width
        .oapp-name
            text-transform uppercase
            margin-top 30px
            font-weight 700
            color black
            font-size 1.8rem
    .card-main
        background-color $dark
        display flex
        flex-direction column
        align-items center
        color white
        .oapp-description
            font-size $fs-h2
            padding 20px
            letter-spacing 0.5px
            text-align center
            margin-bottom 20px
            color white
        .store-button
            font-weight 700
            text-transform uppercase
            cursor pointer
            background-color white
            color $dark
            text-align center
            padding 10px 15px
            border-radius 30px
            margin 20px
            font-size $fs-h1
            transition all 200ms ease
            &--no-margin
                margin 0
            &:hover
                background-color black
                color white
                transition all 200ms ease
        .store-button--disabled
            background-color $grey
            cursor default
            &:hover
                background-color $grey !important
                color $dark !important
        .store-container
            height 90px
            width 100%
            background: url('/assets/ui/PNG/trame-alpha.png');
            background-size contain
            display flex
            align-items center
            justify-content center
            border-width 0px 1px 1px 1px
            border-style solid
            border-color $grey

</style>
